$(function() {
    $.prototype.submitForm = function(_args) {
        let self = this;
        let coll = [];

        if (self.length === 0)
            return;

        let submitForm = function(_self) {
            // form submit button
            let _submit = _self.find('[type="submit"]'),
                _submited = false,
                has_redirect = false,
                no_modal = false,
                errors_attached_to_inputs = true,
                REDIRECT_DELAY = 1000,
                inputs = '',
                $parent = !no_modal ? $(self).closest(".modal") : $(self);

            // get form inputs
            let hasFiles = function() {
                return _self.find('[type="file"]').length;
            };

            // get form inputs
            let getInputs = function() {
                inputs = '';

                // input (!radio), textarea and checked radio inputs
                inputs += 'input:not([type=radio]), textarea, select';
                inputs += ', input[type=radio]:checked';

                // search inputs
                return _self.find(inputs);
            };

            function prettify(str) {
                return str.split('_').map(function capitalize(part) {
                    return part.charAt(0).toUpperCase() + part.slice(1);
                }).join('_');
            }

            // select form data for submit
            let getData = function() {
                let data = new FormData();

                // search inputs
                inputs = getInputs();

                // select submit data
                if(!hasFiles())
                    data = {};

                inputs.each(function(k, v) {
                    let name = $(v).attr('name'),
                        type = $(v).attr('type'),
                        val  = ($(v).val() != null && typeof ($(v).val()) == 'string' && 
                            $(v).prop("tagName").toLowerCase() !== 'textarea') ? ($(v).val()).trim() : $(v).val();
                        
                    if(hasFiles()){
                        if( type === 'checkbox' ){
                            data.append($(v).attr('name'), $(v).prop('checked') ? 1 : 0);
                        }
                        else{
                            if ( typeof name !== 'undefined' ) {
                                if( type === 'file' ){
                                    for(let i = 0 ; i < $(v).get(0).files.length; i++){
                                        data.append(name + '[]', $(v).get(0).files[i]);
                                    }
                                }
                                else{
                                    data.append(name, val);
                                }    
                            }    
                        }
                    }
                    else{
                        if ( typeof name !== 'undefined' ) {
                            if( type === 'checkbox' ){
                                if( !$(v).prop('checked') ){
                                    val = 0;
                                }

                                if ( name.indexOf('[]') === -1 )
                                    val = $(v).prop('checked') ? 1 : 0;
                            }

                            //Check if array
                            if( name.indexOf('[]') !== -1 ){
                                if( typeof data[name] === 'undefined' ) {
                                    data[name] = [];
                                }

                                if(val){
                                    data[name].push(val);
                                }
                            }
                            else{
                                data[name] = val;
                            }
                        }
                    }    
                });

                _self.find('.custom-select p').each(function(k, v) {
                    let name = $(v).attr('data-name'),
                        val = typeof $(v).attr('data-id') != 'undefined' ?
                            $(v).attr('data-id') : '';

                    data[name] = val;    
                });
                
                return data;
            };

            let clearMessages = function(){
                $parent.find("div.alert.response-msg").remove();
                $parent.find(".modal div.alert.response-msg").remove();

                $parent.find("div.success.response-msg").remove();
                $parent.find(".modal div.success.response-msg").remove();

                $(".form-response").remove();
                $(".form-error").remove();
            };

            // prepend response
            let prependResponse = function(message, is_error){
                let message_res = "",
                    resp_element = "<div class='form-error'>",
                    $response = $('<div>').addClass('form-response')
                        .css('text-align', 'center');

                if(typeof message != "object"){
                    message_res = message;
                }

                if(!no_modal){
                    let $modalBodyChild = (!_args.prependTo) ? 
                            $(self).find('.modal-footer') : 
                            $(self).find(_args.prependTo),
                        $modalBodyParent = (!_args.prependTo) ? 
                            $(self).closest('.modal-footer') : 
                            $(self).find(_args.prependTo);

                    $parent = $modalBodyChild.length ? $modalBodyChild : $modalBodyParent;
                }
                else{
                    $parent = $(self);
                }

                if(errors_attached_to_inputs){
                    try {
                        message = JSON.parse(message);
                    }
                    catch(err) {}

                    if(typeof message == "object"){
                        if (message.errors) {
                            $.each(message.errors, function(key, val) {
                                $parent.find("[name='" + key + "']")
                                    .closest('.form-group ')
                                    .append($(resp_element).html(val));
                            });
                        } else {
                            $response.insertBefore($parent)
                                .addClass(is_error ? 'error' : 'success')
                                .html(message.message);
                        }
                    } else {
                        $(resp_element).html(message_res);   

                        $response.insertBefore($parent)
                            .addClass(is_error ? 'error' : 'success')
                            .html(message);
                    }
                } else {
                    if (typeof message == "object") {
                        $.each(message.errors, function(key, val) {
                            message_res += '<ul class="list-unstyled m-0"><li>' + 
                                val + '</li></ul>';
                        });
                    }

                    $(resp_element).html(message_res);    

                    $response.insertBefore($parent)
                        .addClass(is_error ? 'error' : 'success')
                        .html(message);
                }
            };

            // send data to server through ajax request

            let sendData = function(data) {
                if(_args.preSuccess && typeof _args.preSuccess === "function"){
                    _args.preSuccess();
                }

                if(_args.noModal) {
                    no_modal = true;
                }

                let ajax_setting = {
                    url: _args.url,
                    type: _args.type,
                    dataType: _args.dataType,
                    data: (_args.data) ? _args.data : data,
                };

                let extra_settings = {
                    cache: false,
                    contentType: false,
                    processData: false,
                };

                if (hasFiles())
                    $.extend( ajax_setting, extra_settings );
                
                if (_args.overrideHandlers) {
                    $.ajax(ajax_setting)
                    .done(function(response) {
                        _args.done(response);
                        _submited = false;
                    }).fail(function(response) {
                        _args.fail(response);
                        _submited = false;
                    });
                } else {
                    $.ajax(ajax_setting)
                    .done(function(response) {
                        if(response.redirect || response.reload){
                            has_redirect = true;
                        }

                        if (_args.success && typeof _args.success === "function") {
                            _args.success();
                        } else {
                            proceedResponseSuccess(response);
                        }

                        _submited = false;
                    }).fail(function(response) {
                        if (_args.error && typeof _args.error === "function") {
                            _args.error();
                        } else {
                            proceedResponseError(response);
                            
                            _submit.attr('disabled', false);
                        }

                        _submited = false;
                    });
                }
            };

            let proceedResponseSuccess = function(resp) {
                clearMessages();

                if (_args.postSuccess && typeof _args.postSuccess === "function") {
                    _args.postSuccess();
                }

                prependResponse(resp.message, false);

                if (resp.redirect) {
                    window.setTimeout(function() {
                        window.location = resp.redirect;
                    }, REDIRECT_DELAY);
                } else {
                    if(resp.reload){
                        window.setTimeout(function() {
                            location.reload();
                        }, REDIRECT_DELAY);
                    }
                }
            };

            let proceedResponseError = function(message) {
                clearMessages();

                if(!message || typeof message == 'undefined'){
                    prependResponse("Something went wrong!", true);
                }
                else{
                    prependResponse(message.responseText, true);
                }

                if(_args.postError && typeof _args.postError === "function"){
                    _args.postError();
                }
                
                _submit.attr('disabled', false);
            };

            // submit form
            let submitForm = function() {
                _submited = true;
                sendData(getData());
            };

            // bind events
            let bind = function() {
                _submit.attr('disabled', true);

                if (!_submited)
                    submitForm();
            };

            bind();
        };

        for (let i = self.length - 1; i >= 0; i--) {
            coll.push(new submitForm($(self[i])));
        }
    };
});